<template>
  <v-container fluid>
    <v-card flat outlined class="pa-sm-4 wraperx" v-if="dataLoading">
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </v-card>
    <v-card flat outlined class="pa-sm-4 wraperx" v-else>
      <v-card-title class="primary--text">
        Leave Summary
        <v-spacer></v-spacer>
        <v-sheet max-width="120">
          <v-autocomplete
            filled
            flat
            v-model="selecterYear"
            :items="years"
            outlined
            dense
            hide-details
            @change="fetchApplications()"
          ></v-autocomplete>
        </v-sheet>
      </v-card-title>
      <v-card-title
        class="justify-center justify-sm-start"
        v-if="
          $_checkPermission(
            this.auth.permissions,
            'Manage Leave Applications',
            'Read All'
          ) ||
          $_checkPermission(
            this.auth.permissions,
            'Manage Leave Applications',
            'Read Sub'
          )
        "
      >
        <v-card outlined>
          <v-tabs
            v-model="tab"
            @change="changeView"
            background-color="transparent"
          >
            <v-tooltip bottom max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  style="
                    overflow-x: hidden !important;
                    word-break: keep-all;
                    text-overflow: ellipsis !important;
                    max-width: calc(50vw - 50px);
                    min-width: 0 !important;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :tab-value="1"
                  >My</v-tab
                >
              </template>
              <span>View your leave applications</span>
            </v-tooltip>
            <v-divider
              vertical
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Leave Applications',
                  'Read Sub'
                )
              "
            ></v-divider>
            <v-tooltip
              bottom
              max-width="200"
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Leave Applications',
                  'Read Sub'
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  style="
                    overflow-x: hidden !important;
                    word-break: keep-all;
                    text-overflow: ellipsis !important;
                    max-width: calc(50vw - 50px);
                    min-width: 0 !important;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :tab-value="3"
                  >Sub</v-tab
                >
              </template>
              <span>View leave applications of your subordinates</span>
            </v-tooltip>

            <v-divider
              vertical
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Leave Applications',
                  'Read All'
                )
              "
            ></v-divider>
            <v-tooltip
              bottom
              max-width="200"
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Leave Applications',
                  'Read All'
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  style="
                    overflow-x: hidden !important;
                    word-break: keep-all;
                    text-overflow: ellipsis !important;
                    max-width: calc(50vw - 50px);
                    min-width: 0 !important;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :tab-value="2"
                  >All</v-tab
                >
              </template>
              <span>View all leave applications</span>
            </v-tooltip>
          </v-tabs>
        </v-card>
      </v-card-title>
      <v-card-title class="primary--text">
        <v-col style="min-width: 200px">
          <v-autocomplete
            filled
            multiple
            flat
            v-model="selectedStaffTypes"
            :items="staffTypes"
            label="Staff Types"
            outlined
            dense
            clearable
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col style="min-width: 200px">
          <v-autocomplete
            filled
            multiple
            flat
            v-model="staffUuids"
            :items="staff"
            item-text="name"
            item-value="uuid"
            label="Staff"
            outlined
            dense
            clearable
            hide-details
          >
            <template v-slot:selection="{ item, index }">
              <span v-if="index === 0" class="me-1">{{ item.name }}</span>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ staffUuids.length - 1 }} others)
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col style="min-width: 200px">
          <v-btn depressed block color="green" dark @click="exportCSV">
            <v-icon class="me-2"> mdi-file-export-outline </v-icon> export csv
          </v-btn>
        </v-col>
      </v-card-title>
      <v-card
        flat
        outlined
        class="pa-sm-4 mt-6 wraperx dsd"
        id="print-only"
        v-if="selectedStaff.length > 0"
      >
        <v-card-text class="tableText--text">
          <table
            class="imso-table"
            id="imso-log-table"
            v-if="selectedStaff.length > 0"
          >
            <tr>
              <th rowspan="2">S.No</th>
              <th rowspan="2">Emp No.</th>
              <th rowspan="2">Name</th>
              <th rowspan="2">Designation</th>
              <th rowspan="2">Type</th>
              <th rowspan="2">Months worked</th>
              <th rowspan="1" colspan="3">Eligible Leaves</th>
              <th rowspan="1" colspan="5" v-for="month in months" :key="month">
                {{ month }}
              </th>
              <th class="mkm" rowspan="1" colspan="5">Total</th>
            </tr>
            <tr>
              <td>A</td>
              <td>C</td>
              <td>S</td>
              <td v-for="i in 60" :key="i + 'd'">
                {{ ["N", "A", "C", "S", "L"][i % 5] }}
              </td>
              <th class="mkm">A</th>
              <th class="mkm">C</th>
              <th class="mkm">S</th>
              <th class="mkm">L</th>
              <th class="mkm">N</th>
            </tr>
            <tr
              v-for="(person, i) in selectedStaff"
              :key="i"
              :class="i % 2 == 0 ? 'mkm' : ''"
            >
              <td>
                {{ i + 1 }}
              </td>
              <td>
                {{ person.staff_no }}
              </td>
              <td>
                {{ person.name }}
              </td>
              <td>
                {{ person.designation }}
              </td>
              <td>
                {{ person.type }}
              </td>
              <td>
                {{ person.months }}
              </td>
              <td>
                {{ person.eligible.a }}
              </td>
              <td>
                {{ person.eligible.c }}
              </td>
              <td>
                {{ person.eligible.s }}
              </td>
              <td v-for="(leave, i) in person.leaves" :key="i + 'lq'">
                {{ leave }}
              </td>
              <th
                class="mkm"
                v-for="(totalLeave, i) in person.totalLeaves"
                :key="i + 'lt'"
              >
                {{ totalLeave }}
              </th>
            </tr>
          </table>
        </v-card-text>
      </v-card>
    </v-card>
  </v-container>
</template>
      
      
      <script>
import { mapState } from "vuex";
import { fetchLeaveApplications } from "@/services/leaveApplicationsService";
import { fetchAndStoreStaffData } from "@/services/preloadDataService";
import constants from "@/constants.json";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  watch: {
    staffUuids: {
      handler() {
        this.fetchApplications();
      },
      deep: true,
    },
    selectedStaffTypes: {
      handler() {
        this.fetchApplications();
      },
      deep: true,
    },
  },
  data: () => ({
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    years: [],
    selectedStaffTypes: [],
    staffTypes: [],
    selecterYear: 0,
    menu1: false,
    menu2: false,
    tab: 2,
    dataLoading: false,
    loading: false,
    headers: [
      {
        text: "Application Id",
        value: "id",
        sortable: false,
      },
      {
        text: "Date",
        value: "from_date",
        sortable: false,
      },
      {
        text: "Staff",
        value: "staff.first_name",
        sortable: false,
      },
      {
        text: "No of days",
        value: "no_of_days",
        sortable: false,
      },
      {
        text: "Type",
        value: "type",
        sortable: false,
      },
      { text: "Status", value: "review_status", sortable: false },
    ],
    items: [],
    staffUuids: [],
    selectedStaff: [],
    staff: [],
    _staff: [],
  }),
  async created() {
    this.selecterYear = new Date().getFullYear();
    this.staffTypes = constants.staffTypes;

    if (
      this.$_checkPermission(
        this.auth.permissions,
        "Manage Leave Applications",
        "Read All"
      )
    ) {
      this.dataLoading = true;
      this._staff = await fetchAndStoreStaffData();
      this._staff.forEach((person) => {
        person["name"] = person.first_name + " " + person.last_name;
      });
      this.staff = this._staff;
      await this.fetchApplications();
      this.dataLoading = false;
    } else {
      this.$router.push({
        name: "PageDashboard",
      });
    }
  },
  mounted() {
    //
  },
  methods: {
    changeView() {
      if (this.tab == 1) {
        this.$router.push({
          name: "PageMyLeaveSummary",
        });
      }
      if (this.tab == 3) {
        this.$router.push({
          name: "PageSubLeaveSummary",
        });
      }
    },
    calculations() {
      this.selectedStaff.forEach((person) => {
        person.months = this.$_calculateMonthsWorked(
          person.date_of_joining,
          new Date().getFullYear() != this.selecterYear
            ? new Date(this.$_getLastDateOfAYear(this.selecterYear))
            : new Date()
        );
        const pLeaves = this.items.filter(
          (item) => item.staff_uuid == person.uuid
        );
        if (person.type == "Regular Contract Staff") {
          person.eligible = {
            c: (
              (Math.min(
                this.$_calculateMonthsWorked(
                  person.date_of_joining,
                  new Date().getFullYear() != this.selecterYear
                    ? new Date(this.$_getLastDateOfAYear(this.selecterYear))
                    : new Date()
                ),
                12
              ) /
                12) *
              constants.leaves.casual
            ).toFixed(2),
            a: (
              (Math.min(
                this.$_calculateMonthsWorked(
                  person.date_of_joining,
                  new Date().getFullYear() != this.selecterYear
                    ? new Date(this.$_getLastDateOfAYear(this.selecterYear))
                    : new Date()
                ),
                12
              ) /
                12) *
              constants.leaves.annual
            ).toFixed(2),
            s: (
              (Math.min(
                this.$_calculateMonthsWorked(
                  person.date_of_joining,
                  new Date().getFullYear() != this.selecterYear
                    ? new Date(this.$_getLastDateOfAYear(this.selecterYear))
                    : new Date()
                ),
                12
              ) /
                12) *
              constants.leaves.sick
            ).toFixed(2),
          };
        } else {
          person.eligible = {
            a: "-",
            c: "-",
            s: "-",
          };
        }

        person.leaves = [];
        person.totalLeaves = [];

        for (let index = 1; index <= 12; index++) {
          let a = 0,
            c = 0,
            s = 0,
            l = 0,
            n = 0;
          pLeaves.forEach((leave) => {
            if (this.$_getMonthFromDate(leave.from_date) == index) {
              if (leave.type == "ANNUAL") a += leave.no_of_days;
              if (leave.type == "CASUAL") c += leave.no_of_days;
              if (leave.type == "SICK") s += leave.no_of_days;
              if (leave.type == "LIEU") l += leave.no_of_days;
              if (leave.type == "NOPAY") n += leave.no_of_days;
            }
          });

          person.leaves.push(a == 0 ? " " : a);
          person.leaves.push(c == 0 ? " " : c);
          person.leaves.push(s == 0 ? " " : s);
          person.leaves.push(l == 0 ? " " : l);
          person.leaves.push(n == 0 ? " " : n);
        }

        let ta = 0,
          tc = 0,
          ts = 0,
          tl = 0,
          tn = 0;
        pLeaves.forEach((leave) => {
          if (leave.type == "ANNUAL") ta += leave.no_of_days;
          if (leave.type == "CASUAL") tc += leave.no_of_days;
          if (leave.type == "SICK") ts += leave.no_of_days;
          if (leave.type == "LIEU") tl += leave.no_of_days;
          if (leave.type == "NOPAY") tn += leave.no_of_days;
        });
        person.totalLeaves.push(ta == 0 ? " " : ta);
        person.totalLeaves.push(tc == 0 ? " " : tc);
        person.totalLeaves.push(ts == 0 ? " " : ts);
        person.totalLeaves.push(tl == 0 ? " " : tl);
        person.totalLeaves.push(tn == 0 ? " " : tn);
      });
    },
    async fetchApplications() {
      this.loading = true;
      if (this.selectedStaffTypes.length > 0) {
        this.staff = this._staff.filter((item) =>
          this.selectedStaffTypes.includes(item.type)
        );
        if (this.staffUuids.length == 0) {
          this.staff.forEach((element) => {
            this.staffUuids.push(element.uuid);
          });
        }
      } else {
        this.staff = this._staff;
      }
      this.selectedStaff = [];
      for (let i = 0; i < 5; i++) {
        this.years.push(this.selecterYear - i);
      }
      this.items = await fetchLeaveApplications(
        1,
        -1,
        this.$_getFirstDateOfAYear(this.selecterYear),
        this.$_getLastDateOfAYear(this.selecterYear),
        JSON.stringify(this.staffUuids),
        '["APPROVED"]',
        "[]"
      );
      this.items = this.items.data;
      if (this.staffUuids.length == 0) {
        this.selectedStaff = this.staff;
      } else {
        this.selectedStaff = this.staff.filter((item) =>
          this.staffUuids.includes(item.uuid)
        );
      }
      await this.calculations();
      this.loading = false;
    },
    exportCSV() {
      const tableIds = ["imso-log-table"];
      const filename = "Leaves - " + this.selecterYear;

      const csv = [];
      tableIds.forEach((tableId) => {
        const table = document.getElementById(tableId);
        if (table) {
          const rows = table.querySelectorAll("tr");

          // Track the grid position for rowspan and colspan cells
          const cellGrid = [];
          let rowIndex = 0;

          // Loop through each row of the table
          for (rowIndex; rowIndex < rows.length; rowIndex++) {
            const row = rows[rowIndex];
            const cells = row.querySelectorAll("th, td");
            const rowData = [];
            let columnIndex = 0;

            // Ensure grid is initialized for this row
            if (!cellGrid[rowIndex]) {
              cellGrid[rowIndex] = [];
            }

            // Process each cell in the current row
            for (let cell of cells) {
              // Skip over grid positions that are already occupied by a rowspan/colspan
              while (cellGrid[rowIndex][columnIndex]) {
                columnIndex++;
              }

              // Get cell content and replace commas to avoid CSV issues
              let cellContent = cell.textContent.replace(/[,|\n]/g, " ");

              // Add cell content to rowData
              rowData[columnIndex] = cellContent;

              // Handle colspan
              let colspan = cell.getAttribute("colspan") || 1;
              colspan = parseInt(colspan);

              // Handle rowspan
              let rowspan = cell.getAttribute("rowspan") || 1;
              rowspan = parseInt(rowspan);

              // Mark the grid for this cell's colspan and rowspan
              for (let r = 0; r < rowspan; r++) {
                if (!cellGrid[rowIndex + r]) {
                  cellGrid[rowIndex + r] = [];
                }
                for (let c = 0; c < colspan; c++) {
                  cellGrid[rowIndex + r][columnIndex + c] = true;
                }
              }

              // Move the column index forward by the colspan amount
              columnIndex += colspan;
            }

            // Add row data to CSV, ensuring commas separate the columns
            csv.push(rowData.join(","));
          }
        }
      });

      // Join all rows with a newline character to form the CSV string
      const csvContent = csv.join("\n");

      // Create a temporary download link and trigger the download
      const blob = new Blob([csvContent], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;

      // Append the link to the document and trigger the click event
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link after download
      document.body.removeChild(link);
    },
  },
};
</script>

<style>
.dsd {
  overflow-x: scroll !important;
}

.mkm {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>